<template>
  <div id="cps-platform-editor">
    添加或编辑平台信息
    <div class="form-warp">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="小程序名称">
          <el-input v-model="form.mpName" placeholder="请填写小程序名称"/>
        </el-form-item>
        <el-form-item label="AppID">
          <el-input v-model="form.appID" placeholder="请填写小程序AppID"/>
        </el-form-item>
        <el-form-item label="原始ID">
          <el-input v-model="form.originalID" placeholder="请填写小程序原始ID"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">下一步</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPSPlatformEditor',
  data () {
    return {
      id: '',
      form: {
        mpName: '',
        appID: '',
        originalID: ''
      }
    }
  },

  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.onLoadDetails(this.id)
    }
  },

  methods: {
    /**
     * 提交信息
     */
    onSubmit: function () {
      if (!this.form.mpName) {
        this.$message.warning('请填写小程序名称')
        return
      }
      if (!this.form.appID) {
        this.$message.warning('请填写小程序的AppID')
        return
      }
      if (!this.form.originalID) {
        this.$message.warning('请填写小程序的原始ID')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '正在提交',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const formData = new FormData()
      formData.append('welfare_id', this.id)
      formData.append('mp_name', this.form.mpName)
      formData.append('appid', this.form.appID)
      formData.append('original_id', this.form.originalID)
      this.$http.request({
        baseURL: 'https://ershou.xiaoyuanmax.com/index.php/api',
        url: '/Extension/actionWelfareEditApi',
        method: 'post',
        data: formData
      }).then(res => {
        loading.close()
        if (res.data.code === 200) {
          this.$router.replace({ path: '/cpsPlatformManagement' })
          this.$message.success('添加成功')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    onCancel: function () {
      this.$router.back()
    },

    onLoadDetails: function (id) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const formData = new FormData()
      formData.append('welfare_id', id)
      this.$http.request({
        baseURL: 'https://ershou.xiaoyuanmax.com/index.php/api',
        url: '/Extension/actionWelfareInfoApi',
        method: 'post',
        data: formData
      }).then(res => {
        loading.close()
        if (res.data.code === 200) {
          const _data = res.data.data
          this.form = {
            mpName: _data.mp_name,
            appID: _data.appid,
            originalID: _data.original_id
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style lang="stylus">
  #cps-platform-editor
    .form-warp
      margin-top 16px
      background-color white
      padding 32px 16px
      border-radius 4px

      .el-form
        width 50%
</style>
