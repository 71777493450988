<template>
    <div id="home">
        没有首页
    </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>

</style>
