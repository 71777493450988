<template>
  <div id="index">
    <el-container class="main-container">
      <!-- Header -->
      <el-header>
        <div class="header-container">
          <div class="logo-warp">
            <h1 class="title">校元 Operation CMS</h1>
          </div>
        </div>
      </el-header>
      <!-- Menu & Container -->
      <el-container>
        <el-aside width="200px">
          <el-menu router default-active="1-4-1" class="el-menu-vertical-demo" @open="handleOpen"
                   @close="handleClose" :collapse="isCollapse">
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-files"/>
                <span slot="title">校元赞助</span>
              </template>
              <el-submenu index="1-1">
                <span slot="title">虚拟用户管理</span>
                <el-menu-item index="/companyUsers">企业用户</el-menu-item>
                <el-menu-item index="/clubUsers">社团用户</el-menu-item>
              </el-submenu>
              <el-submenu index="1-2">
                <span slot="title">虚拟活动管理</span>
                <el-menu-item index="/sponsorshipManager">企业赞助</el-menu-item>
                <el-menu-item index="/eventManager">社团活动</el-menu-item>
              </el-submenu>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-present"/>
                <span slot="title">CPS联盟</span>
              </template>
              <el-menu-item index="/cpsPlatformManagement">渠道平台管理</el-menu-item>
              <el-menu-item index="/cpsManagement">二手市场推广渠道管理</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main class="router-view-warp">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      isCollapse: false,
      homePath: '/'
    }
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>

<style lang="stylus">
  #index
    .main-container
      height 100vh

      .header-container
        width 100%
        height 100%
        display flex
        flex-direction row
        align-items center

        .logo-warp
          display flex
          flex-direction row
          align-items center

          .title
            font-size 20px
            font-weight bold
            color white

      .router-view-warp
        background-color #F9F9F9

    .el-header
      position: relative;
      height 60px
      background-color #2988fc
      box-shadow: 0 2px 4px 0 rgba(4 30 103 0.1);
      z-index 200

    .el-aside
      display block
      position absolute
      left 0
      top 60px
      bottom 0

    .el-main
      position absolute
      left 200px
      right 0
      top 60px
      bottom 0
      overflow-y scroll

    .el-menu
      border 0

    .el-menu-vertical-demo:not(.el-menu--collapse)
      width 200px
      min-height 400px
</style>
