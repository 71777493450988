<template>
  <div id="cps-management">
    <div class="header-warp">
      <!-- 检索区 -->
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="推广名称">
          <el-input v-model="formInline.keywords" placeholder="请输推广名称查询"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 添加推广链接按钮 -->
      <el-button type="primary" @click="onCPSCreate">添加推广链接</el-button>
    </div>
    <!-- 推广链接列表 -->
    <el-table
      v-loading="loading"
      class="cps-table"
      :data="tableData">
      <el-table-column
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        prop="channel_name"
        label="推广名称"
        width="180"
        :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
        label="图标"
        width="100">
        <template slot-scope="scope">
          <el-image
            style="width: 60px; height: 60px"
            :src="scope.row.icon"
            fit="fit"
            :preview-src-list="[scope.row.icon]"/>
        </template>
      </el-table-column>
      <el-table-column
        label="活动图"
        width="200">
        <template slot-scope="scope">
          <el-image
            style="width: 160px; height: 90px"
            :src="scope.row.imgurl"
            fit="fit"
            :preview-src-list="[scope.row.imgurl]"/>
        </template>
      </el-table-column>
      <el-table-column
        label="H5链接"
        width="240">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.h5_link" placement="top-start">
            <span class="link" style="margin-right: 10px">{{ scope.row.h5_link }}</span>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="点击复制完整链接" placement="top-start">
            <i class="el-icon-document-copy"
               v-clipboard:copy="scope.row.h5_link"
               v-clipboard:success="onCopySuccess"
               v-clipboard:error="onCopyError"/>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="小程序链接"
        width="240">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.mp_link" placement="top-start">
            <span class="link" style="margin-right: 10px">{{ scope.row.mp_link }}</span>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="点击复制完整链接" placement="top-start">
            <i class="el-icon-document-copy"
               v-clipboard:copy="scope.row.mp_link"
               v-clipboard:success="onCopySuccess"
               v-clipboard:error="onCopyError"/>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_at"
        label="创建时间"
        width="120">
      </el-table-column>
      <el-table-column
        prop="hits"
        label="点击量">
      </el-table-column>
      <el-table-column
        label="操作"
        width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="warning"
            @click="handleEdit(scope.$index, scope.row)">编辑
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDel(scope.$index, scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="pagination-warp">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[50, 100, 150, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPSManagement',
  data () {
    return {
      loading: true,
      currentPage: 1,
      pageSize: 50,
      total: 0,
      formInline: {
        keywords: ''
      },
      tableData: []
    }
  },

  mounted () {
    this.onLoadCPS()
  },

  methods: {
    /**
     * 添加推广链接
     */
    onCPSCreate: function () {
      this.$router.push('/cpsEditor')
    },

    /**
     * 编辑
     */
    handleEdit: function (index, row) {
      this.$router.push({
        path: '/cpsEditor',
        query: {
          id: row.id
        }
      })
    },

    /**
     * 删除
     */
    handleDel: function (index, row) {
      this.$confirm('删除后将无法恢复此推广信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const formData = new FormData()
        formData.append('extension_id', row.id)
        this.$http.request({
          baseURL: 'https://ershou.xiaoyuanmax.com/index.php/api',
          url: '/Extension/actionExtensionDelApi',
          method: 'post',
          data: formData
        }).then(res => {
          if (res.data.code === 200) {
            this.$message.success('删除成功')
            this.onLoadCPS()
          } else {
            this.$message.error(res.data.message)
          }
        })
      })
    },

    /**
     * 通过翻页器修改当前页面的回调方法
     *
     * @param val 修改后的当前页码
     */
    handleCurrentChange: function (val) {
      this.currentPage = val
      this.onLoadCPS()
    },

    /**
     * 修改每页数据容量的回调方法
     *
     * @param val 修改后的页容量
     */
    handleSizeChange: function (val) {
      this.pageSize = val
      this.onLoadCPS()
    },

    /**
     * 加载数据
     */
    onLoadCPS: function () {
      this.loading = true
      const formData = new FormData()
      formData.append('entry_mode', '2')
      formData.append('channel_name', this.formInline.keywords)
      formData.append('pageCurrent', this.currentPage)
      formData.append('pageSize', this.pageSize)
      this.$http.request({
        baseURL: 'https://ershou.xiaoyuanmax.com/index.php/api',
        url: '/Extension/actionExtensionListApi',
        method: 'post',
        data: formData
      }).then(res => {
        if (res.data.code === 200) {
          this.tableData = [...res.data.data.ExtensionList]
          this.total = res.data.data.PageList.totalNumber
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      })
    },

    onSubmit: function () {
      this.currentPage = 1
      this.onLoadCPS()
    },

    /**
     * 复制到前切板成功回调函数
     */
    onCopySuccess: function () {
      this.$message.success('复制成功')
    },

    /**
     * 复制到前切板失败回调函数
     */
    onCopyError: function () {
      this.$message.error('复制失败')
    }
  }
}
</script>

<style lang="stylus">
  #cps-management
    .pagination-warp
      width 100%
      background-color white
      padding 16px
      display flex
      flex-direction row
      justify-content flex-end
      box-sizing border-box
      border-radius 0 0 4px 4px

    .cps-table
      width 100%
      padding 0 16px
      margin-top 16px
      border-radius 4px 4px 0 0

      .cell
        display flex

      .popover-cell
        margin-bottom 8px

      .link
        cursor pointer
        max-width 180px
        height 20px
        line-height 20px
        display inline-block
        white-space nowrap
        text-overflow ellipsis
        overflow hidden

      .el-icon-document-copy
        cursor pointer
        line-height 20px
        color #2988fc

    .header-warp
      background-color white
      padding 22px 16px 0 16px
      display flex
      flex-direction row
      justify-content space-between
      align-items flex-start
      border-radius 4px

    .tag-sponsorship-form
      cursor pointer
</style>
