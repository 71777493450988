<template>
  <div id="company-users">
    <div class="header-warp">
      <!-- 检索区 -->
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="公司名称">
          <el-input v-model="formInline.user" placeholder="请输入公司名称查询"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 添加用户按钮 -->
      <el-button type="primary" @click="onCreateUser">添加用户</el-button>
    </div>
    <!-- 可选用户列表 -->
    <el-table
      v-loading="loading"
      class="user-table"
      :data="tableData">
      <el-table-column
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        prop="nickname"
        label="姓名"
        width="180">
      </el-table-column>
      <el-table-column
        prop="enterprise_name"
        label="企业名称"
        width="320">
      </el-table-column>
      <el-table-column
        prop="department_name"
        label="部门名称"
        width="240">
      </el-table-column>
      <el-table-column
        prop="enterprise_scale"
        label="企业规模"
        width="180">
      </el-table-column>
      <el-table-column
        prop="enterprise_nature"
        label="企业性质">
      </el-table-column>
      <el-table-column
        label="操作"
        width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handlePublish(scope.$index, scope.row)">发布
          </el-button>
          <el-button
            size="mini"
            type="warning"
            @click="handleEdit(scope.$index, scope.row)">编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="pagination-warp">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[50, 100, 150, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyUser',
  data () {
    return {
      currentPage: 1,
      pageSize: 50,
      total: 0,
      formInline: {
        user: ''
      },
      tableData: [],
      loading: true
    }
  },
  methods: {

    /**
     * 通过翻页器修改当前页面的回调方法
     *
     * @param val 修改后的当前页码
     */
    handleCurrentChange: function (val) {
      this.currentPage = val
      this.onLoadUsers()
    },

    /**
     * 修改每页数据容量的回调方法
     *
     * @param val 修改后的页容量
     */
    handleSizeChange: function (val) {
      this.pageSize = val
      this.onLoadUsers()
    },

    /**
     * 查询对应企业名称下的信息
     */
    onSubmit: function () {
      this.currentPage = 1
      this.onLoadUsers()
    },

    /**
     * 发布对应赞助信息
     *
     * @param index 数据坐标
     * @param row 当前行的数据
     */
    handlePublish: function (index, row) {
      console.log(row)
      this.$router.push({
        path: '/sponsorshipPublishEditor',
        query: { uid: row.id }
      })
    },

    /**
     * 编辑用户信息
     *
     * @param index 数据坐标
     * @param row 当前行的数据
     */
    handleEdit: function (index, row) {
      this.$message.warning('功能暂未开发')
    },

    /**
     * 添加用户
     */
    onCreateUser: function () {
      this.$router.push({
        path: '/createUser',
        query: { type: '1' }
      })
    },

    /**
     * 加载列表
     */
    onLoadUsers: function () {
      this.loading = true
      const formData = new FormData()
      formData.append('certificationType', '1')
      formData.append('pageCurrent', this.currentPage)
      formData.append('pageSize', this.pageSize)
      formData.append('search_name', this.formInline.user)
      this.$http.request({
        url: '/Falsedata/actionUserList',
        method: 'post',
        data: formData
      }).then(res => {
        this.loading = false
        if (res.data.code === 200) {
          this.tableData = [...res.data.data.UserList]
          this.total = res.data.data.PageList.totalNumber
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  },

  mounted () {
    this.onLoadUsers()
  }
}
</script>

<style lang="stylus">
  #company-users
    .pagination-warp
      width 100%
      background-color white
      padding 16px
      display flex
      flex-direction row
      justify-content flex-end
      box-sizing border-box
      border-radius 0 0 4px 4px

    .user-table
      width 100%
      padding 0 16px
      margin-top 16px
      border-radius 4px 4px 0 0

    .header-warp
      background-color white
      padding 22px 16px 0 16px
      display flex
      flex-direction row
      justify-content space-between
      align-items flex-start
      border-radius 4px
</style>
