<template>
  <div id="login">
    <img class="img-background" src="../assets/images/bg-login.png" alt="">
    <div class="login-box">
      <img class="ic-flowerpot" src="../assets/images/ic-flowerpot.png" alt="">
      <span class="title">校元 Operation CMS</span>
      <el-form class="login-form" ref="form" :model="form" label-position="top" label-width="80px">
        <el-form-item label="账号">
          <el-input type="email" v-model="form.account" placeholder="请输入邮箱账号"/>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="form.password" placeholder="请输入密码"/>
        </el-form-item>
        <el-form-item class="btn-login-item">
          <el-button class="btn-login" type="primary" @click="onLogin">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      form: {
        account: '',
        password: ''
      }
    }
  },
  mounted () {
    this.form.account = localStorage.getItem('account')
  },
  methods: {
    onLogin: function () {
      const formData = new FormData()
      formData.append('account', this.form.account)
      formData.append('password', this.form.password)
      this.$http.request({
        baseURL: 'https://os.xiaoyuanmax.com/cms',
        url: '/Login/actionLoginSave',
        method: 'post',
        data: formData
      }).then(res => {
        if (res.data.code === 200) {
          localStorage.setItem('account', this.form.account)
          this.$router.push({ path: '/home' })
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style lang="stylus">
  #login
    height 100vh

    .img-background
      width 1160px
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)

    .login-box
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      width 400px
      padding 48px
      margin-top -48px
      background-color white
      border-radius 12px
      box-shadow 0 3px 8px rgba(48, 49, 51, 0.08)

      .ic-flowerpot
        width 80px
        position absolute
        top 30px
        right 30px

      .title
        color #606266
        font-size 20px
        font-weight bold

      .login-form
        margin-top 24px

        .el-form-item
          margin-bottom 8px

        .el-form-item__label
          padding 0

        .btn-login-item
          margin-top 32px

          .btn-login
            width 100%
</style>
