<template>
  <div id="cps-platform-management">
    <div class="header-warp">
      <!-- 检索区 -->
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="小程序名称">
          <el-input v-model="formInline.keywords" placeholder="请输入小程序名称查询"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 添加推广链接按钮 -->
      <el-button type="primary" @click="onCPSPlatformCreate">添加推广链接</el-button>
    </div>
    <!-- 推广链接列表 -->
    <el-table
      v-loading="loading"
      class="cps-platform-table"
      :data="tableData">
      <el-table-column
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        prop="mp_name"
        label="小程序名称"
        width="240">
      </el-table-column>
      <el-table-column
        prop="appid"
        label="AppID"
        width="240">
      </el-table-column>
      <el-table-column
        prop="original_id"
        label="原始ID">
      </el-table-column>
      <el-table-column
        label="操作"
        width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="warning"
            @click="handleEdit(scope.$index, scope.row)">编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="pagination-warp">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[50, 100, 150, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPSPlatformManagement',
  data () {
    return {
      loading: true,
      currentPage: 1,
      pageSize: 50,
      total: 0,
      formInline: {
        keywords: ''
      },
      tableData: []
    }
  },

  mounted () {
    this.onLoadCPSPlatforms()
  },

  methods: {

    /**
     * 添加推广链接
     */
    onCPSPlatformCreate: function () {
      this.$router.push('/cpsPlatformEditor')
    },

    /**
     * 编辑
     */
    handleEdit: function (index, row) {
      this.$router.push({
        path: '/cpsPlatformEditor',
        query: {
          id: row.id
        }
      })
    },

    /**
     * 通过翻页器修改当前页面的回调方法
     *
     * @param val 修改后的当前页码
     */
    handleCurrentChange: function (val) {
      this.currentPage = val
      this.onLoadCPSPlatforms()
    },

    /**
     * 修改每页数据容量的回调方法
     *
     * @param val 修改后的页容量
     */
    handleSizeChange: function (val) {
      this.pageSize = val
      this.onLoadCPSPlatforms()
    },

    /**
     * 加载数据
     */
    onLoadCPSPlatforms: function () {
      this.loading = true
      const formData = new FormData()
      formData.append('mp_name', this.formInline.keywords)
      formData.append('pageCurrent', this.currentPage)
      formData.append('pageSize', this.pageSize)
      this.$http.request({
        baseURL: 'https://ershou.xiaoyuanmax.com/index.php/api',
        url: '/Extension/actionWelfareListApi',
        method: 'post',
        data: formData
      }).then(res => {
        if (res.data.code === 200) {
          this.tableData = [...res.data.data.WelfareList]
          this.total = res.data.data.PageList.totalNumber
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      })
    },

    onSubmit: function () {
      this.currentPage = 1
      this.onLoadCPSPlatforms()
    }
  }
}
</script>

<style lang="stylus">
  #cps-platform-management
    .pagination-warp
      width 100%
      background-color white
      padding 16px
      display flex
      flex-direction row
      justify-content flex-end
      box-sizing border-box
      border-radius 0 0 4px 4px

    .cps-platform-table
      width 100%
      padding 0 16px
      margin-top 16px
      border-radius 4px 4px 0 0

      .cell
        display flex

    .header-warp
      background-color white
      padding 22px 16px 0 16px
      display flex
      flex-direction row
      justify-content space-between
      align-items flex-start
      border-radius 4px

    .tag-sponsorship-form
      cursor pointer
</style>
