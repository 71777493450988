<template>
  <div id="create-user">
    创建用户
    <div class="form-warp">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="用户昵称">
          <el-input v-model="form.name"/>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :file-list="form.avatar"
            :on-change="onAvatarSelected"
            :limit="1">
            <i slot="default" class="el-icon-plus"/>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail"
                   :src="file[`url`]" alt="">
              <span class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview"
                                  @click="handlePictureCardPreview(file)">
                                <i class="el-icon-zoom-in"/>
                            </span>
                            <span class="el-upload-list__item-delete"
                                  @click="handleRemoveAvatar">
                                <i class="el-icon-delete"/>
                            </span>
                        </span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">下一步</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CreateUser',
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      form: {
        name: '',
        avatar: []
      }
    }
  },
  methods: {
    /**
     * 删除图片
     */
    handleRemoveAvatar: function () {
      this.form.avatar = []
    },

    /**
     * 预览图片
     *
     * @param file 需要查看大图的文件
     */
    handlePictureCardPreview: function (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    /**
     * 图片列表发生变化时的回调函数
     *
     * @param file 当前添加或删除的图片信息
     * @param fileList 操作后返回的图片列表
     */
    onAvatarSelected: function (file, fileList) {
      console.log(file)
      this.form.avatar = [...fileList]
    },

    /**
     * 表单提交按钮的点击事件
     */
    onSubmit: function () {
      if (!this.form.name) {
        this.$message.warning('请填写用户昵称')
        return
      }
      if (this.form.avatar.length === 0) {
        this.$message.warning('请上传头像')
        return
      }
      const formData = new FormData()
      formData.append('user_name', this.form.name)
      formData.append('file', this.form.avatar[0].raw)
      this.$http.request({
        url: '/Falsedata/actionUserAddApi',
        method: 'post',
        data: formData
      }).then(res => {
        if (res.data.code === 200) {
          this.$router.push({
            path: this.$route.query.type === '1' ? '/companyIdentifyEditor' : '/clubIdentifyEditor',
            query: { uid: res.data.data.user_id }
          })
        }
      })
    },

    /**
     * 取消点击事件
     */
    onCancel: function () {
      this.$router.back()
    }
  }
}
</script>

<style lang="stylus">
  #create-user
    .form-warp
      margin-top 16px
      background-color white
      padding 32px 16px
      border-radius 4px

      .el-form
        width 50%

        .avatar-uploader
          .el-upload
            border 1px dashed #d9d9d9
            border-radius 6px
            cursor pointer
            position relative
            overflow hidden
            width 146px
            height 146px

            &:hover
              border-color #409EFF

          .el-upload-list--picture-card
            width 146px
            height 146px

            .el-upload-list__item
              width 146px
              height 146px

            .el-upload-list__item-thumbnail
              width 146px
              height 146px
</style>
