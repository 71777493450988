import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CompanyUsers from '../views/CompanyUsers.vue'
import ClubUsers from '../views/ClubUsers.vue'
import CreateUser from '../views/CreateUser'
import CompanyIdentifyEditor from '../views/CompanyIdentifyEditor'
import SponsorshipPublishEditor from '../views/SponsorshipPublishEditor'
import SponsorshipManager from '../views/SponsorshipManager'
import ClubIdentifyEditor from '../views/ClubIdentifyEditor'
import EventPublishEditor from '../views/EventPublishEditor'
import EventManager from '../views/EventManager'
import CPSManagement from '../views/CPSManagement'
import CPSEditor from '../views/CPSEditor'
import CPSPlatformManagement from '../views/CPSPlatformManagement'
import CPSPlatformEditor from '../views/CPSPlatformEditor'
import Login from '../views/Login'
import Index from '../views/Index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      }, {
        path: '/companyUsers',
        name: 'CompanyUsers',
        component: CompanyUsers
      }, {
        path: '/clubUsers',
        name: 'ClubUsers',
        component: ClubUsers
      }, {
        path: '/createUser',
        name: 'CreateUser',
        component: CreateUser
      }, {
        path: '/companyIdentifyEditor',
        name: 'CompanyIdentifyEditor',
        component: CompanyIdentifyEditor
      }, {
        path: '/sponsorshipPublishEditor',
        name: 'SponsorshipPublishEditor',
        component: SponsorshipPublishEditor
      }, {
        path: '/eventPublishEditor',
        name: 'EventPublishEditor',
        component: EventPublishEditor
      }, {
        path: '/sponsorshipManager',
        name: 'SponsorshipManager',
        component: SponsorshipManager
      }, {
        path: '/clubIdentifyEditor',
        name: 'ClubIdentifyEditor',
        component: ClubIdentifyEditor
      }, {
        path: '/eventManager',
        name: 'EventManager',
        component: EventManager
      }, {
        path: '/cpsManagement',
        name: 'CPSManagement',
        component: CPSManagement
      }, {
        path: '/cpsEditor',
        name: 'CPSEditor',
        component: CPSEditor
      }, {
        path: '/cpsPlatformManagement',
        name: 'CPSPlatformManagement',
        component: CPSPlatformManagement
      }, {
        path: '/cpsPlatformEditor',
        name: 'CPSPlatformEditor',
        component: CPSPlatformEditor
      }]
  }, {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('account')) {
    if (to.path !== '/login') {
      next('/login')
    }
  }
  next()
})

export default router
