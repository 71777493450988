<template>
  <div id="company-identify-editor">
    填写认证信息
    <div class="form-warp">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="真实姓名">
          <el-input v-model="form.name"/>
        </el-form-item>
        <el-form-item label="企业名称">
          <el-input v-model="form.companyName"/>
        </el-form-item>
        <el-form-item label="部门名称">
          <el-input v-model="form.departmentName"/>
        </el-form-item>
        <el-form-item label="企业规模">
          <el-select v-model="form.scale" placeholder="请选择">
            <el-option
              v-for="item in scales"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业性质">
          <el-select v-model="form.property" placeholder="请选择">
            <el-option
              v-for="item in properties"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">下一步</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyIdentifyEditor',
  data () {
    return {
      form: {
        name: '',
        companyName: '',
        departmentName: '',
        scale: '',
        property: ''
      },
      scales: [
        '50人以下',
        '50-100人',
        '100-200人',
        '200-300人',
        '300-500人',
        '500-1000人',
        '1000人以上'
      ],
      properties: ['国企', '私营企业', '股份制企业', '上市公司', '国家机关', '事业单位', '其他']
    }
  },
  methods: {
    onSubmit: function () {
      if (!this.form.name) {
        this.$message.warning('请填写真实姓名')
        return
      }
      if (!this.form.companyName) {
        this.$message.warning('请填写企业名称')
        return
      }
      if (!this.form.departmentName) {
        this.$message.warning('请填写部门名称')
        return
      }
      if (!this.form.scale) {
        this.$message.warning('请选择企业规模')
        return
      }
      if (!this.form.property) {
        this.$message.warning('请选择企业性质')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '正在提交',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const formData = new FormData()
      formData.append('user_id', this.$route.query.uid + '')
      formData.append('nickname', this.form.name)
      formData.append('enterprise_name', this.form.companyName)
      formData.append('department_name', this.form.departmentName)
      formData.append('enterprise_nature', this.form.property)
      formData.append('enterprise_scale', this.form.scale)
      this.$http.request({
        url: '/Falsedata/actionSponsorSaveApi',
        method: 'post',
        data: formData
      }).then(res => {
        loading.close()
        if (res.data.code === 200) {
          this.$router.replace({ path: '/companyUsers' })
          this.$message.success('创建成功')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    onCancel: function () {
      this.$router.back()
    }
  }
}
</script>

<style lang="stylus">
  #company-identify-editor
    .form-warp
      margin-top 16px
      background-color white
      padding 32px 16px
      border-radius 4px

      .el-form
        width 50%
</style>
