<template>
  <div id="club-identify-editor">
    填写认证信息
    <div class="form-warp">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="真实姓名">
          <el-input v-model="form.name"/>
        </el-form-item>
        <el-form-item label="学校">
          <el-select v-model="form.campusId" filterable placeholder="请选择所属学校">
            <el-option-group
              v-for="(group,index) in campusNameOptions"
              :key="index"
              :label="group.letter">
              <el-option
                v-for="(item, index) in group.university_name"
                :key="index"
                :label="item.university_name"
                :value="item.id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="社团名称">
          <el-input v-model="form.clubName"/>
        </el-form-item>
        <el-form-item label="社团职位">
          <el-select v-model="form.position" placeholder="请选择社团职位">
            <el-option
              v-for="(item, index) in positionOptions"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="社团规模">
          <el-select v-model="form.scale" placeholder="请选择社团规模">
            <el-option
              v-for="(item, index) in scales"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">下一步</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyIdentifyEditor',
  data () {
    return {
      form: {
        name: '',
        campusId: '',
        clubName: '',
        scale: '',
        position: ''
      },
      campusNameOptions: [],
      scales: ['10人以下', '10-20人', '20-50人', '50-80人', '80-100人', '100人以上'],
      positionOptions: ['主席', '副主席', '会长', '副会长', '部长', '副部长', '干事']
    }
  },

  mounted () {
    this.onLoadCampus()
  },

  methods: {
    onLoadCampus: function () {
      const formData = new FormData()
      formData.append('lat', '0')
      formData.append('lng', '0')
      this.$http.request({
        url: '/Square/actionChoiceUniversityApi',
        method: 'post',
        data: formData
      }).then(res => {
        if (res.data.code === 200) {
          this.campusNameOptions = res.data.data.AllUniversityList
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    onSubmit: function () {
      if (!this.form.name) {
        this.$message.warning('请填写真实姓名')
        return
      }
      if (!this.form.campusId) {
        this.$message.warning('请选择学校')
        return
      }
      if (!this.form.clubName) {
        this.$message.warning('请填写社团名称')
        return
      }
      if (!this.form.position) {
        this.$message.warning('请选择您的职位')
        return
      }
      if (!this.form.scale) {
        this.$message.warning('请选择社团规模')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '正在提交',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const formData = new FormData()
      formData.append('user_id', this.$route.query.uid + '')
      formData.append('nickname', this.form.name)
      formData.append('universities_id', this.form.campusId)
      formData.append('association_name', this.form.clubName)
      formData.append('association_position', this.form.position)
      formData.append('association_scale', this.form.scale)
      this.$http.request({
        url: '/Falsedata/actionAssociationSaveApi',
        method: 'post',
        data: formData
      }).then(res => {
        loading.close()
        if (res.data.code === 200) {
          this.$router.replace({ path: '/clubUsers' })
          this.$message.success('创建成功')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    onCancel: function () {
      this.$router.back()
    }
  }
}
</script>

<style lang="stylus">
  #club-identify-editor
    .form-warp
      margin-top 16px
      background-color white
      padding 32px 16px
      border-radius 4px

      .el-form
        width 50%
</style>
