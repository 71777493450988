<template>
  <div id="sponsorship-manager">
    <div class="header-warp">
      <!-- 检索区 -->
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="赞助标题">
          <el-input v-model="formInline.keywords" placeholder="请输入赞助标题查询"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 可选用户列表 -->
    <el-table
      v-loading="loading"
      class="user-table"
      :data="tableData">
      <el-table-column
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        prop="sponsor_title"
        label="标题"
        width="320"
        :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
        prop="nickname"
        label="发布者"
        width="120">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p class="popover-cell">企业名称：{{ scope.row.enterprise_name }}</p>
            <p class="popover-cell">企业性质：{{ scope.row.enterprise_nature }}</p>
            <p>企业规模：{{ scope.row.enterprise_scale }}</p>
            <div slot="reference" class="name-wrapper">
              <el-tag
                class="tag-sponsorship-form"
                style="margin-right: 8px"
                disable-transitions>{{scope.row.nickname}}
              </el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="sponsor_type"
        label="赞助方式"
        width="160">
      </el-table-column>
      <el-table-column
        prop="region"
        label="地区"
        width="240">
      </el-table-column>
      <el-table-column
        prop="sponsorshipForm"
        label="赞助类型"
        width="240">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p class="popover-cell" v-if="scope.row.sponsorship_amount">赞助金额：{{ scope.row.sponsorship_amount }}</p>
            <p v-if="scope.row.material">物资内容：{{ scope.row.material }}</p>
            <div slot="reference" class="name-wrapper">
              <el-tag
                class="tag-sponsorship-form"
                v-if="scope.row.sponsorshipForm.indexOf('资金赞助') > -1"
                type="danger"
                effect="dark"
                style="margin-right: 8px"
                disable-transitions>资金赞助
              </el-tag>
              <el-tag
                class="tag-sponsorship-form"
                v-if="scope.row.sponsorshipForm.indexOf('物资赞助') > -1"
                type="success"
                effect="dark"
                disable-transitions>物资赞助
              </el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="date"
        label="赞助时间">
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作"
        width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="warning"
            @click="handleEdit(scope.$index, scope.row)">编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="pagination-warp">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[50, 100, 150, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SponsorshipManager',
  data () {
    return {
      loading: true,
      currentPage: 1,
      pageSize: 50,
      total: 0,
      formInline: {
        keywords: ''
      },
      tableData: []
    }
  },
  methods: {
    /**
     * 编辑
     */
    handleEdit: function () {
      this.$message.warning('功能暂未开放')
    },

    /**
     * 通过翻页器修改当前页面的回调方法
     *
     * @param val 修改后的当前页码
     */
    handleCurrentChange: function (val) {
      this.currentPage = val
      this.onLoadSponsorshipInfo()
    },

    /**
     * 修改每页数据容量的回调方法
     *
     * @param val 修改后的页容量
     */
    handleSizeChange: function (val) {
      this.pageSize = val
      this.onLoadSponsorshipInfo()
    },

    /**
     * 加载数据
     */
    onLoadSponsorshipInfo: function () {
      this.loading = true
      const formData = new FormData()
      formData.append('sponsor_title', this.formInline.keywords)
      formData.append('pageCurrent', this.currentPage)
      formData.append('pageSize', this.pageSize)
      this.$http.request({
        url: '/Falsedata/actionSponsorList',
        method: 'post',
        data: formData
      }).then(res => {
        if (res.data.code === 200) {
          res.data.data.SponsorList.forEach(item => {
            item.sponsorshipForm = []
            if (item.is_cash === '1') {
              item.sponsorshipForm.push('资金赞助')
            }
            if (item.is_material === '1') {
              item.sponsorshipForm.push('物资赞助')
            }
            if (item.s_province === '全部') {
              item.region = '全国'
            } else {
              item.region = item.s_province + ' ' + item.s_city + ' ' + item.s_county
            }
            item.date = item.sponsor_start_time + ' - ' + item.sponsor_end_time
          })
          this.tableData = [...res.data.data.SponsorList]
          this.total = res.data.data.PageList.totalNumber
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      })
    },

    onSubmit: function () {
      this.currentPage = 1
      this.onLoadSponsorshipInfo()
    }
  },
  mounted () {
    this.onLoadSponsorshipInfo()
  }
}
</script>

<style lang="stylus">
  #sponsorship-manager
    .pagination-warp
      width 100%
      background-color white
      padding 16px
      display flex
      flex-direction row
      justify-content flex-end
      box-sizing border-box
      border-radius 0 0 4px 4px

    .user-table
      width 100%
      padding 0 16px
      margin-top 16px
      border-radius 4px 4px 0 0

      .popover-cell
        margin-bottom 8px

    .header-warp
      background-color white
      padding 22px 16px 0 16px
      display flex
      flex-direction row
      justify-content space-between
      align-items flex-start
      border-radius 4px

    .tag-sponsorship-form
      cursor pointer
</style>
