<template>
  <div id="cps-editor">
    添加或编辑推广链接
    <div class="form-warp">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="推广名称">
          <el-input v-model="form.channelName" placeholder="请填写推广的渠道名称"/>
        </el-form-item>
        <el-form-item label="所属平台">
          <el-select v-model="form.platform" placeholder="请选择所属平台">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.mp_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="H5链接">
          <el-input v-model="form.linkForH5" placeholder="请填写推广跳转的H5链接"/>
        </el-form-item>
        <el-form-item label="小程序链接">
          <el-input v-model="form.linkForWXMp" placeholder="请填写推广跳转的小程序链接"/>
        </el-form-item>
        <el-form-item label="活动描述">
          <el-input type="textarea" v-model="form.description" placeholder="请简要描述活动信息"/>
        </el-form-item>
        <el-form-item label="图标">
          <el-upload
            class="icon-uploader"
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :file-list="form.icon"
            :on-change="onIconSelected"
            :class="{hide:hideIconUpload}"
            :limit="1">
            <i slot="default" class="el-icon-plus"/>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail"
                   :src="file[`url`]" alt="">
              <span class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview"
                                  @click="handlePictureCardPreview(file)">
                                <i class="el-icon-zoom-in"/>
                            </span>
                            <span class="el-upload-list__item-delete"
                                  @click="handleRemoveIcon">
                                <i class="el-icon-delete"/>
                            </span>
                        </span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="活动图">
          <el-upload
            class="cover-uploader"
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :file-list="form.cover"
            :on-change="onCoverSelected"
            :class="{hide:hideCoverUpload}"
            :limit="1">
            <i slot="default" class="el-icon-plus"/>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail"
                   :src="file[`url`]" alt="">
              <span class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview"
                                  @click="handlePictureCardPreview(file)">
                                <i class="el-icon-zoom-in"/>
                            </span>
                            <span class="el-upload-list__item-delete"
                                  @click="handleRemoveCover">
                                <i class="el-icon-delete"/>
                            </span>
                        </span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">下一步</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CPSEditor',
  data () {
    return {
      id: '',
      dialogImageUrl: '',
      dialogVisible: false,
      hideIconUpload: false,
      hideCoverUpload: false,
      platforms: [],
      form: {
        channelName: '',
        platform: '',
        linkForH5: '',
        linkForWXMp: '',
        description: '',
        icon: [],
        cover: []
      }
    }
  },

  mounted () {
    this.id = this.$route.query.id
    this.onLoadDetails(this.id)
  },

  methods: {
    /**
     * 删除图标
     */
    handleRemoveIcon: function () {
      this.form.icon = []
      this.hideIconUpload = false
    },

    /**
     * 删除活动图
     */
    handleRemoveCover: function () {
      this.form.cover = []
      this.hideCoverUpload = false
    },

    /**
     * 预览图片
     *
     * @param file 需要查看大图的文件
     */
    handlePictureCardPreview: function (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    /**
     * 上传图标时的回调函数
     *
     * @param file 当前添加或删除的图片信息
     * @param fileList 操作后返回的图片列表
     */
    onIconSelected: function (file, fileList) {
      console.log(file)
      this.form.icon = [...fileList]
      this.hideIconUpload = true
    },

    /**
     * 上传活动图时的回调函数
     *
     * @param file 当前添加或删除的图片信息
     * @param fileList 操作后返回的图片列表
     */
    onCoverSelected: function (file, fileList) {
      console.log(file)
      this.form.cover = [...fileList]
      this.hideCoverUpload = true
    },

    /**
     * 提交信息
     */
    onSubmit: function () {
      if (!this.form.channelName) {
        this.$message.warning('请填写推广名称')
        return
      }
      if (!this.form.linkForH5) {
        this.$message.warning('请填写H5链接')
        return
      }
      if (!this.form.linkForWXMp) {
        this.$message.warning('请填写小程序链接')
        return
      }
      if (!this.form.description) {
        this.$message.warning('请填写活动描述')
        return
      }
      if (this.form.icon.length === 0) {
        this.$message.warning('请上传推广图标')
        return
      }
      if (this.form.cover.length === 0) {
        this.$message.warning('请上传活动图')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '正在提交',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const formData = new FormData()
      formData.append('extension_id', this.id || '')
      formData.append('channel_name', this.form.channelName)
      formData.append('h5_link', this.form.linkForH5)
      formData.append('mp_link', this.form.linkForWXMp)
      formData.append('describe', this.form.description)
      formData.append('type_id', this.form.platform)
      const reg = RegExp(/blob:/)
      if (reg.exec(this.form.icon[0].url)) {
        // 包含
        formData.append('icon', this.form.icon[0].raw)
      }
      if (reg.exec(this.form.cover[0].url)) {
        // 包含
        formData.append('imgurl', this.form.cover[0].raw)
      }
      this.$http.request({
        baseURL: 'https://ershou.xiaoyuanmax.com/index.php/api',
        url: '/Extension/actionExtensionSaveApi',
        method: 'post',
        data: formData
      }).then(res => {
        loading.close()
        if (res.data.code === 200) {
          this.$router.replace({ path: '/cpsManagement' })
          this.$message.success('添加成功')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    onCancel: function () {
      this.$router.back()
    },

    onLoadDetails: function (id) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const formData = new FormData()
      formData.append('extension_id', id || '')
      this.$http.request({
        baseURL: 'https://ershou.xiaoyuanmax.com/index.php/api',
        url: '/Extension/actionExtensionInfoApi',
        method: 'post',
        data: formData
      }).then(res => {
        loading.close()
        if (res.data.code === 200) {
          const _data = res.data.data.ExtensionInfo
          // 当处于编辑状态时才渲染表单数据
          if (id) {
            this.form = {
              channelName: _data.channel_name,
              linkForH5: _data.h5_link,
              linkForWXMp: _data.mp_link,
              description: _data.describe,
              icon: [{ url: _data.icon }],
              cover: [{ url: _data.imgurl }],
              platform: _data.type_id
            }
            this.hideCoverUpload = true
            this.hideIconUpload = true
          }
          // 无论是否处于编辑状态，都需要渲染所属平台信息的选择器数据
          this.platforms = [...res.data.data.welfare]
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style lang="stylus">
  #cps-editor
    .form-warp
      margin-top 16px
      background-color white
      padding 32px 16px
      border-radius 4px

      .el-form
        width 50%

        .el-form-item__content
          line-height 1

          .cover-uploader
            display flex
            flex-direction row

            .el-upload
              border 1px dashed #d9d9d9
              border-radius 6px
              cursor pointer
              position relative
              overflow hidden
              width 160px
              height 90px
              display flex
              align-items center
              justify-content center

              &:hover
                border-color #409EFF

            .el-upload-list--picture-card
              .el-upload-list__item
                width 160px
                height 90px

              .el-upload-list__item-thumbnail
                width 160px
                height 90px
                object-fit cover

          .icon-uploader
            display flex
            flex-direction row

            .el-upload
              display flex
              align-items center
              justify-content center
              border 1px dashed #d9d9d9
              border-radius 6px
              cursor pointer
              position relative
              overflow hidden

              &:hover
                border-color #409EFF

            .el-upload-list--picture-card
              .el-upload-list__item
                margin-bottom 0

              .el-upload-list__item-thumbnail
                object-fit cover

          .hide .el-upload--picture-card
            display none
</style>
