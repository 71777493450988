<template>
  <div id="sponsorship-publish-editor">
    填写企业赞助信息
    <div class="form-warp">
      <el-form ref="form" :model="form" label-width="80px">
        <!-- 赞助标题 -->
        <el-form-item label="赞助标题">
          <el-input v-model="form.title" placeholder="请填写赞助标题"/>
        </el-form-item>
        <!-- 赞助类型 -->
        <el-form-item label="赞助类型">
          <el-select v-model="form.sponsorshipType" placeholder="请选择">
            <el-option
              v-for="item in sponsorshipTypes"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 赞助时间 -->
        <el-form-item label="赞助时间">
          <el-date-picker
            v-model="form.sponsorDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <!-- 发布时间 -->
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="form.createAt"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <!-- 赞助地点 -->
        <el-form-item label="赞助地点">
          <el-cascader
            size="large"
            :options="options"
            v-model="regionSelectedOptions"
            @change="handleRegionChange"
          >
          </el-cascader>
        </el-form-item>
        <!-- 推广目的 -->
        <el-form-item label="推广目的">
          <el-select v-model="form.promotion"
                     multiple
                     collapse-tags
                     placeholder="请选择">
            <el-option
              v-for="item in promotionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 推广方式 -->
        <el-form-item label="推广方式">
          <el-select
            v-model="form.promotionType"
            multiple
            collapse-tags
            placeholder="请选择">
            <el-option
              v-for="item in promotionTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 赞助形式 -->
        <el-form-item label="赞助形式">
          <el-checkbox-group v-model="form.sponsorshipForm">
            <el-checkbox label="现金赞助"/>
            <el-checkbox label="物资赞助"/>
          </el-checkbox-group>
        </el-form-item>
        <!-- 赞助资金 -->
        <transition name="el-fade-in">
          <el-form-item v-if="form.sponsorshipForm.indexOf('现金赞助') > -1" label="赞助资金">
            <el-input v-model="form.totalMoney" placeholder="请输入赞助的金额"/>
          </el-form-item>
        </transition>
        <!-- 物资内容 -->
        <transition name="el-fade-in">
          <el-form-item v-if="form.sponsorshipForm.indexOf('物资赞助') > -1" label="物资内容">
            <el-input v-model="form.totalMaterial" placeholder="请输入赞助的物资内容"/>
          </el-form-item>
        </transition>
        <!-- 联系人 -->
        <el-form-item label="联系人">
          <el-input v-model="form.contacts" placeholder="请输入联系人姓名"/>
        </el-form-item>
        <!-- 联系电话 -->
        <el-form-item label="联系电话">
          <el-input v-model="form.contactsTel" placeholder="请输入联系电话"/>
        </el-form-item>
        <!-- 微信 -->
        <el-form-item label="微信">
          <el-input v-model="form.contactsWeChat" placeholder="请输入联系人微信号（选填）"/>
        </el-form-item>
        <!-- 赞助详情 -->
        <el-form-item label="赞助详情">
          <el-input type="textarea" v-model="form.desc"/>
        </el-form-item>
        <!-- 提交 -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit">下一步</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import regionData from '../../src/assets/js/region.js'

export default {
  name: 'SponsorshipPublishEditor',
  data () {
    return {
      options: regionData,
      regionSelectedOptions: [],
      form: {
        title: '',
        sponsorshipType: [],
        sponsorDate: '',
        createAt: '',
        province: '',
        city: '',
        county: '',
        promotion: [], // 推广目的
        promotionType: [],
        sponsorshipForm: [],
        totalMoney: '',
        totalMaterial: '',
        contacts: '',
        contactsTel: '',
        contactsWeChat: '',
        desc: ''
      },
      promotionOptions: [
        {
          value: '市场推广',
          label: '市场推广'
        }, {
          value: '品牌营销',
          label: '品牌营销'
        }, {
          value: '产品销售',
          label: '产品销售'
        }
      ],
      promotionTypeOptions: [
        {
          value: '朋友圈',
          label: '朋友圈'
        }, {
          value: '社群',
          label: '社群'
        }, {
          value: '场地',
          label: '场地'
        }, {
          value: '派单',
          label: '派单'
        }, {
          value: '扫楼',
          label: '扫楼'
        }, {
          value: '宣讲会',
          label: '宣讲会'
        }, {
          value: '线上宣传',
          label: '线上宣传'
        }, {
          value: '校内媒体',
          label: '校内媒体'
        }, {
          value: '其他',
          label: '其他'
        }
      ],
      sponsorshipTypes: [
        '线上投票',
        '才艺竞赛',
        '社会实践',
        '体育竞技',
        '设计开发',
        '讲座论坛',
        '迎新招新',
        '演讲辩论',
        '晚会典礼',
        '高校推广',
        '影视音乐'
      ] // 赞助类型
    }
  },
  methods: {
    /**
     * 地址选择器回调事件
     */
    handleRegionChange () {
      if (this.regionSelectedOptions[0] === '000000') {
        // 对选中”全国“的数据进行特殊处理，保证与小程序端判断一致
        this.form.province = '全部'
        this.form.city = '全部'
        this.form.county = '全部'
      } else {
        for (let i = 0; i < regionData.length; i++) {
          const item = regionData[i]
          if (item.value === this.regionSelectedOptions[0]) {
            // 根据code获取省
            this.form.province = item.label
            item.children.forEach(item => {
              if (item.value === this.regionSelectedOptions[1]) {
                // 根据code获取市
                this.form.city = item.label
                item.children.forEach(item => {
                  if (item.value === this.regionSelectedOptions[2]) {
                    // 根据code获取县区
                    this.form.county = item.label
                  }
                })
              }
            })
          }
        }
      }
    },
    onSubmit: function () {
      if (!this.form.title) {
        this.$message.warning('请输入标题')
        return false
      }
      if (!this.form.sponsorshipType) {
        this.$message.warning('请选择赞助类型')
        return false
      }
      let startDate
      let endDate
      if (!this.form.sponsorDate) {
        this.$message.warning('请选择赞助时间')
        return false
      } else {
        startDate = new Date(this.form.sponsorDate[0])
        startDate = startDate.getFullYear() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getDate()
        endDate = new Date(this.form.sponsorDate[1])
        endDate = endDate.getFullYear() + '/' + (endDate.getMonth() + 1) + '/' + endDate.getDate()
      }
      let createAt
      if (!this.form.createAt) {
        this.$message.warning('请选择发布时间')
        return false
      } else {
        createAt = new Date(this.form.createAt)
        createAt = createAt.getFullYear() + '/' + (createAt.getMonth() + 1) + '/' + createAt.getDate()
      }
      if (this.regionSelectedOptions.length === 0) {
        this.$message.warning('请选择赞助地点')
        return false
      }
      if (this.form.promotion.length === 0) {
        this.$message.warning('请选择推广目的')
        return false
      }
      if (this.form.promotionType.length === 0) {
        this.$message.warning('请选择推广方式')
        return false
      }
      if (this.form.sponsorshipForm.length === 0) {
        this.$message.warning('至少选择一种赞助形式')
        return false
      }
      if (this.form.sponsorshipForm.indexOf('现金赞助') > -1 && !this.form.totalMoney) {
        this.$message.warning('请输入赞助金额')
        return false
      }
      if (this.form.sponsorshipForm.indexOf('物资赞助') > -1 && !this.form.totalMaterial) {
        this.$message.warning('请输入赞助的物资内容')
        return false
      }
      if (!this.form.contacts) {
        this.$message.warning('请填写联系人姓名')
        return false
      }
      if (!this.form.contactsTel) {
        this.$message.warning('请填写联系人电话')
        return false
      }
      if (!this.form.desc) {
        this.$message.warning('请填写赞助详情')
        return false
      }
      const formData = new FormData()
      // TODO 编辑时提交赞助的 id
      formData.append('sponsor_id', '')
      formData.append('user_id', this.$route.query.uid + '')
      formData.append('sponsor_title', this.form.title)
      formData.append('sponsor_type', this.form.sponsorshipType)
      formData.append('sponsor_start_time', startDate)
      formData.append('sponsor_end_time', endDate)
      formData.append('create_at', createAt)
      formData.append('s_province', this.form.province)
      formData.append('s_city', this.form.city)
      formData.append('s_county', this.form.county)
      formData.append('promotion_purpose', JSON.stringify(this.form.promotion))
      formData.append('promotion_mode', JSON.stringify(this.form.promotionType))
      formData.append('contacts', this.form.contacts)
      formData.append('contact_tel', this.form.contactsTel)
      formData.append('wechat', this.form.contactsWeChat)
      formData.append('sponsor_infor', this.form.desc)
      formData.append('is_cash', this.form.sponsorshipForm.indexOf('现金赞助') > -1 ? '1' : '0')
      formData.append('sponsorship_amount', this.form.totalMoney)
      formData.append('is_material', this.form.sponsorshipForm.indexOf('物资赞助') > -1 ? '1' : '0')
      formData.append('material', this.form.totalMaterial)
      // TODO 增加上传附件功能时添加文件地址
      formData.append('sponsor_annex', '')
      this.$http.request({
        url: '/Falsedata/actionSponsorAddApi',
        method: 'post',
        data: formData
      }).then(res => {
        if (res.data.code === 200) {
          this.$router.replace({ path: '/companyUsers' })
          this.$message.success('创建成功')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    onCancel: function () {
      this.$router.back()
    }
  }
}
</script>

<style lang="stylus">
  #sponsorship-publish-editor

    .form-warp
      margin-top 16px
      background-color white
      padding 32px 16px
      border-radius 4px

      .el-form
        width 50%
</style>
